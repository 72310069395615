import common from '@/common';
import kioskUtils from '@/libs/kiosk-utils';


let form_options = {}
form_options.company = []
form_options.platforms = []
form_options.package_versions = []

let package_versions_orig = []

export async function reloadFieldData(isEdit = false) {
  // init Arrays
  ;[
    form_options.company,
    form_options.platforms,
    form_options.package_versions,
    package_versions_orig,
  ].forEach(arr => arr.length = 0)

  if (!isEdit) {
    kioskUtils.getKioskEnvOptions().then(({ envOptions, platformOptions }) => {
      platformOptions.forEach(option => form_options.platforms.push(option))
    })
  }
  common.getCompanyOptions()
    .then(companyOptions => {
      form_options.company.push.apply(form_options.company, companyOptions)
    })
  await _getPackageVersionsOrig()
}

async function _getPackageVersionsOrig() {
  package_versions_orig.length = 0
  const res = await common.apiGetData('/kioskupdate/GetPackageList')
  res.data?.packages.forEach(item => {
    package_versions_orig.push(item)
    // package_versions_orig.push({
    //   text: `${item.version} | ${item.packageName}`,
    //   value: item.packageID,
    //   platform: item.platform,
    //   tooltip: item.remark,
    // })
  })
}

export async function reloadTargetVersionOptions(vm) {
  // await _getPackageVersionsOrig()
  form_options.package_versions = package_versions_orig
    .filter(item => item.platform === vm.rowData['platform'])
    .map(option => ({
      text: `${option.version} | ${option.packageName}`,
      value: option.packageID,
      tooltip: option.remark ? `備註： ${option.remark}` : `備註： （空）`,
    }))
  vm.fields.find(field => field.name === 'packageID').options = form_options.package_versions
}

export default [
  {
    name: 'platform',
    label: 'kiosk.device_platform',
    type: 'select',
    rule: 'required',
    edit_disable: true,
    options: form_options.platforms,
    on_input: (vm, value) => {
      vm.rowData['packageID'] = ''
      vm.rowData['kioskTokens'] = []
      reloadTargetVersionOptions(vm)
    },
  },
  {
    name: 'packageID',
    label: '目標版本',
    rule: 'required',
    show_fn: (vm) => vm.rowData['platform'] !== '',
    options: form_options.package_versions,
    type: 'select',
    // slot: 'package',
    // slot_config: {}
    showTooltip: true,
  },
  {
    name: 'upgradeRange',
    label: '升级范围',
    type: 'select',
    rule: 'required',
    edit_disable: true,
    options: [
      { text: '所有設備', value: 'all' },
      { text: '特定設備', value: 'custom' },
    ],
    on_input: (vm, value) => {
      vm.rowData['kioskTokens'] = []
    },
  },
  {
    name: 'companyID',
    label: 'common.company',
    type: 'select',
    rule: 'required',
    edit_disable: true,
    options: form_options.company,
    show_fn: (vm) => (
      !vm['isEdit'] && vm.rowData['upgradeRange'] === 'custom'
    ) || (
      vm['isEdit'] && vm.rowData['companyID']
    ),
  },
  {
    name: 'facilityID',
    label: 'common.facility',
    type: 'slot',
    rule: 'required',
    slot: 'facility',
    show_fn: (vm) => (
      !vm['isEdit'] && vm.rowData['upgradeRange'] === 'custom'
    ) || (
      vm['isEdit'] && vm.rowData['facilityID']
    ),
  },
  {
    name: 'kioskTokens',
    label: 'Kiosk 設備',
    type: 'slot',
    rule: 'required',
    slot: 'kiosks',
    show_fn: (vm) => !vm['isEdit'] && vm.rowData['upgradeRange'] === 'custom',
  },
  {
    name: 'kioskName',
    label: 'Kiosk 設備',
    type: 'text',
    rule: 'required',
    edit_disable: true,
    show_fn: (vm) => vm['isEdit'],
  },
]
